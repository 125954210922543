.header {
  display: flex;
  gap: 60px;
  background: #313131;
  padding: 12px;
  @media screen and (max-width: 800px) {
    gap: 16px;
    flex-wrap: wrap;
  }
}
.header__logo {
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  img {
    max-width: 360px;
    @media screen and (max-width: 800px) {
      max-width: 200px;
    }
  }
}
.header__link {
  display: flex;
  align-items: center;
  gap: 12px;
  p {
    font-size: 16px;
    color: #f7f6f6;
    text-transform: uppercase;
    @media screen and (max-width: 800px) {
      font-size: 14px;
    }
  }
}
